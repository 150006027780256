import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NextLink from 'next/link';
import track from '@pcid/analytics/track';
import { autoFormatMessage } from '@pcid/string-utils';
import Sprite from '@pcid/sprite';

const Link = ({
	to,
	children,
	className = '',
	caret = false,
	display = 'inline',
	theme,
	ariaLabel,
	onClick,
	disabled,
	focusOnLoad = false,
	isInternal,
	...rest
}) => {
	const internal = [true, false].includes(isInternal) ? isInternal : to[0] === '/';
	const classes = classNames([
		'link',
		'link--theme-base',
		theme ? `link--theme-${theme}` : '',
		`link--${display}`,
		!internal && 'link--external',
		theme === 'button' && `button button--theme-base button--${display}`,
		className,
	]);
	const anchorRef = React.createRef();

	const anchor = (
		<a
			className={classes}
			href={disabled ? null : to}
			ref={anchorRef}
			onClick={(e) => {
				if (!internal) {
					track.click({
						event_category: 'outbound',
						event_label: to,
						transport_type: 'beacon',
					});
				}
				if (onClick) onClick(e);
			}}
			aria-label={ariaLabel && autoFormatMessage(ariaLabel)}
			{...rest}
		>
			<span>{children}</span>
			{ caret && <Sprite className="link__caret" group="icons" symbol="chevron-right" /> }
		</a>
	);

	useEffect(() => {
		if (focusOnLoad) {
			anchorRef.current.focus();
		}
	}, []);

	if (internal) {
		return (
			<NextLink legacyBehavior href={disabled ? null : to}>
				{anchor}
			</NextLink>
		);
	}

	return anchor;
};

Link.propTypes = {
	to: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.node,
	caret: PropTypes.bool,
	display: PropTypes.oneOf([
		'block',
		'inline',
	]),
	focusOnLoad: PropTypes.bool,
	ariaLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
};

export default Link;
