import {
	getRelyingPartyStorage,
	getLoginCtxStorage,
	getAuthFactorStorage,
} from '@pcid/storage-utils';
import { getStoreName } from '@pcid/utils/relying-party-utils';
import { autoFormatMessage } from '@pcid/string-utils';
import track, { tap, trackSnowplowEvent } from '@pcid/analytics/track';
import analyticsConstants from '@pcid/analytics/constants';
import { localeManager } from '@pcid/locale-utils';
import { getPageViewPayload as getOopsPayload } from '@pcid/utils/oops';
import constants from './constants';

const { linkName: { CREATE_ACCOUNT_EMAIL_EXISTS } } = analyticsConstants;

const { linkName: {
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	SSO_LOGIN_FAILURE,
	LOGOUT_PAGE,
	LOGOUT_SUCCESS_PAGE,
	MFA_CHALLENGE_SUCCESS,
	MFA_CHALLENGE_FAILURE,
	MFA_RESEND_CODE,
	MFA_SELECT_METHOD_BACK,
	MFA_SELECT_METHOD_SUBMIT,
	MFA_SELECT_METHOD_SUCCESS,
	MFA_USE_EMAIL_INSTEAD,
	MFA_USE_SMS_INSTEAD,
	BACK_BUTTON,
	SET_UP_2_STEP_VERIFICATION,
	MFA_SETUP_SUCCESS,
	SUBMIT_PHONE_NUMBER,
	CANCEL_MFA,
	FORGOT_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAILURE,
	CREATE_ACCOUNT_SUCCESS,
	CREATE_ACCOUNT_FAILURE,
	UPDATE_EMAIL_SUCCESS,
	UPDATE_EMAIL_FAILURE,
	ERROR,
	CASL_ENABLED,
} } = constants;

const { pageName: {
	LOGIN_PAGE,
	MFA_CHALLENGE_PAGE,
	UPDATE_SECURITY_PAGE,
	PROTECT_ACCOUNT_PAGE,
	ENTER_NUMBER_PAGE,
	WHY_MFA_PAGE,
	CONTINUE,
	LOGIN_SUCCESS_PAGE,
	ADD_VERIFICATION_CODE,
	FORGOT_PASSWORD_PAGE,
	CREATE_ACCOUNT_PAGE,
	UPDATE_EMAIL_PAGE,
	RESET_PASSWORD_PAGE,
	RESET_PASSWORD_SUCCESS_PAGE,
	TERMS_PAGE,
} } = constants;

const { pageSection: {
	LOGIN_SECTION,
	CREATE_ACCOUNT_SECTION,
	ACCOUNT_MANAGEMENT_SECTION,
	POLICY_SECTION,
} } = constants;

const { loginSource: { ACCOUNT_MANAGEMENT } } = constants;

const getSnowplowSsoSession = () => {
	const rpName = getRelyingPartyStorage().get();
	const storeNameRaw = getStoreName(rpName);
	const storeName = autoFormatMessage(storeNameRaw);
	return { rpName, storeName };
};

const snowplowExceptionClear = () => {
	trackSnowplowEvent({
		event: 'exception_clear',
		exception: null,
	});
};

export const getPageViewPayload = (pathname, query) => {
	let gtmPayload;
	switch (pathname) {
		case '/login': {
			const relyingParty = getRelyingPartyStorage().get();
			if (relyingParty && getLoginCtxStorage().getIn(relyingParty)) {
				gtmPayload = {
					pageName: LOGIN_PAGE,
					pageSection: LOGIN_SECTION,
				};
			} else {
				gtmPayload = null;
			}
		}
			break;
		case '/login/verification':
			if (query && query.resend) {
				gtmPayload = null;
			} else {
				gtmPayload = {
					pageName: MFA_CHALLENGE_PAGE,
					pageSection: LOGIN_SECTION,
					methodSelection: getAuthFactorStorage().get().method,
				};
			}
			break;
		case '/login/verification-required':
			if (query && query.resend) {
				gtmPayload = null;
			} else {
				const authFactor = getAuthFactorStorage().get().method.toLowerCase();
				gtmPayload = {
					pageName: `${ADD_VERIFICATION_CODE}-${authFactor}`,
					pageSection: LOGIN_SECTION,
					methodSelection: authFactor,
				};
			}
			break;
		case '/login/update-security':
			gtmPayload = {
				pageName: UPDATE_SECURITY_PAGE,
				pageSection: LOGIN_SECTION,
			};
			break;
		case '/login/protect':
			gtmPayload = {
				pageName: PROTECT_ACCOUNT_PAGE,
				pageSection: LOGIN_SECTION,
				loginSource: getRelyingPartyStorage().get(),
			};
			break;
		case '/login/enter-number':
			gtmPayload = {
				pageName: ENTER_NUMBER_PAGE,
				pageSection: LOGIN_SECTION,
				loginSource: getRelyingPartyStorage().get(),
			};
			break;
		case '/login/why-mfa':
			gtmPayload = {
				pageName: WHY_MFA_PAGE,
				pageSection: LOGIN_SECTION,
				loginSource: getRelyingPartyStorage().get(),
			};
			break;
		case '/continue':
			gtmPayload = {
				pageName: CONTINUE,
				pageSection: LOGIN_SECTION,
			};
			break;
		case '/logout':
			gtmPayload = {
				pageName: LOGOUT_PAGE,
				pageSection: LOGIN_SECTION,
			};
			break;
		case '/logout/success':
			gtmPayload = {
				pageName: LOGOUT_SUCCESS_PAGE,
				pageSection: LOGIN_SECTION,
			};
			break;
		case '/login/success':
			gtmPayload = {
				pageName: LOGIN_SUCCESS_PAGE,
				pageSection: LOGIN_SECTION,
			};
			break;
		case '/forgot-password':
			gtmPayload = {
				pageName: FORGOT_PASSWORD_PAGE,
				pageSection: LOGIN_SECTION,
			};
			break;
		case '/create-account':
			gtmPayload = {
				pageName: CREATE_ACCOUNT_PAGE,
				pageSection: CREATE_ACCOUNT_SECTION,
			};
			break;
		case '/reset-password':
			if (query.token) {
				gtmPayload = {
					pageName: RESET_PASSWORD_PAGE,
					pageSection: LOGIN_SECTION,
				};
			} else {
				gtmPayload = null;
			}
			break;
		case 'reset-password/success':
			gtmPayload = {
				pageName: RESET_PASSWORD_SUCCESS_PAGE,
				pageSection: ACCOUNT_MANAGEMENT_SECTION,
			};
			break;
		case '/terms':
			gtmPayload = {
				pageName: TERMS_PAGE,
				pageSection: POLICY_SECTION,
			};
			break;
		case '/update-email':
			gtmPayload = {
				pageName: UPDATE_EMAIL_PAGE,
				pageSection: LOGIN_SECTION,
				loginSource: ACCOUNT_MANAGEMENT,
			};
			break;
		case '/oops':
			gtmPayload = getOopsPayload(query);
			break;
		default: gtmPayload = null;
	}
	const deviceType = window.innerWidth < 860 ? 'mobile' : 'desktop';
	const snowplowPayload = {
		event: 'page_view',
		page: {
			page_name: gtmPayload && gtmPayload.pageName,
			page_section: gtmPayload && gtmPayload.pageSection,
			page_language: localeManager.getLocale(),
			site_type: deviceType,
			page_type: pathname === '/oops' ? '404' : null,
		},
		sso_session: {
			client: getSnowplowSsoSession().rpName,
			branding: getSnowplowSsoSession().storeName,
		},
	};
	return { gtmPayload, snowplowPayload };
};

export const trackGenericException = tap((error) => {
	const { tags } = error;
	track.exception({
		...tags,
		linkName: ERROR,
		errorType: 'server',
	});
	throw error;
});

export const trackGenericExceptionSnowplow = tap((error) => {
	const { tags } = error;
	snowplowExceptionClear();
	trackSnowplowEvent({
		event: 'exception',
		exception: {
			type: 'error',
			message: tags.errorMessage,
			code: tags.errorCode,
			process: tags.req.url,
			user_informed: true,
		},
	});
	throw error;
});

export const trackLoginSuccess = tap(() => {
	track.customLink({
		linkName: LOGIN_SUCCESS,
		loginStatus: true,
	});
});

export const trackKmsiLoginFailure = tap(() => {
	snowplowExceptionClear();
	trackSnowplowEvent({
		event: 'exception',
		exception: {
			type: 'error',
			message: autoFormatMessage('form.login.ssoLoginError'),
			code: SSO_LOGIN_FAILURE,
			process: '/login',
			user_informed: true,
		},
	});
});

export const trackLoginFailure = tap((error) => {
	const { tags, message } = error;
	if (tags.errorCode === 'AUTH-3001') {
		track.customLink({
			...tags,
			errorMessage: autoFormatMessage(message),
			linkName: LOGIN_FAILURE,
			errorType: 'application',
		});
		snowplowExceptionClear();
		trackSnowplowEvent({
			event: 'exception',
			exception: {
				type: 'error',
				message: autoFormatMessage(message),
				code: LOGIN_FAILURE,
				process: tags.req.url,
				user_informed: true,
			},
		});
		throw error;
	} else {
		trackGenericException(error);
		trackGenericExceptionSnowplow(error);
	}
});

export const trackMFALoginSuccess = tap((_, { trustedDevice, authFactor }) => {
	track.customLink({
		linkName: MFA_CHALLENGE_SUCCESS,
		loginStatus: true,
		trustDevice: trustedDevice,
		methodSelection: authFactor,
	});
	snowplowExceptionClear();
	trackSnowplowEvent({
		event: 'exception',
		exception: {
			type: 'success',
			message: MFA_CHALLENGE_SUCCESS,
			process: '/mfa-challenge',
			user_informed: false,
		},
	});
});

export const trackMFALoginFailure = tap((error, { authFactor }) => {
	const { tags } = error;
	if (tags.errorCode === 'INVALID_PASSCODE') {
		track.customLink({
			...tags,
			linkName: MFA_CHALLENGE_FAILURE,
			errorType: tags.errorType,
			methodSelection: authFactor,
		});
		snowplowExceptionClear();
		trackSnowplowEvent({
			event: 'exception',
			exception: {
				type: 'error',
				message: tags.errorMessage,
				code: tags.errorCode,
				process: tags.req.url,
				user_informed: true,
			},
		});
		throw error;
	} else {
		trackGenericException(error);
		trackGenericExceptionSnowplow(error);
	}
});

export const trackMFAResendCode = tap((payload) => {
	const { authFactor: method } = payload;
	track.customLink({
		linkName: MFA_RESEND_CODE,
		methodSelection: method,
	});
});

export const trackEmailAlreadyExists = tap((error) => {
	const { tags: { errorCode } } = error;
	if (errorCode === 'EMAIL_UNAVAILABLE') {
		track.customLink({
			linkName: CREATE_ACCOUNT_EMAIL_EXISTS,
			errorCode,
			errorMessage: autoFormatMessage({
				id: 'form.createAccount.alreadyHaveAnAccount',
				values: { email: 'obscured@example.com' },
			}),
			errorType: 'application',
		});
		snowplowExceptionClear();
		trackSnowplowEvent({
			event: 'exception',
			exception: {
				type: 'warning',
				message: autoFormatMessage({
					id: 'form.createAccount.alreadyHaveAnAccount',
					values: { email: 'obscured@example.com' },
				}),
				code: errorCode,
				process: error.tags.req.url,
				user_informed: true,
			},
		});
	}
	throw error;
});

export const trackForgotPasswordEmail = tap(({ message, sourcePage }) => {
	const values = { email: 'obscured@example.com' };
	if (sourcePage === 'forgotPassword') {
		track.customLink({
			linkName: FORGOT_PASSWORD,
			successMessage: autoFormatMessage({ ...message, values }),
		});
		snowplowExceptionClear();
		trackSnowplowEvent({
			event: 'exception',
			exception: {
				type: 'success',
				message: autoFormatMessage({ ...message, values }),
				code: FORGOT_PASSWORD,
				process: '/forgot-password',
				user_informed: true,
			},
		});
	}
});

export const trackUpdateEmailFailure = tap((error) => {
	const { tags, message } = error;
	track.customLink({
		...tags,
		linkName: UPDATE_EMAIL_FAILURE,
		loginSource: ACCOUNT_MANAGEMENT,
		errorMessage: autoFormatMessage({
			...message,
			values: {
				...message.values,
				email: 'obscured@example.com',
			},
		}),
		errorType: 'application',
	});
	snowplowExceptionClear();
	trackSnowplowEvent({
		event: 'exception',
		exception: {
			type: 'error',
			message: autoFormatMessage({
				...message,
				values: {
					...message.values,
					pcid: 'PC™id',
					email: 'obscured@example.com',
				},
			}),
			code: UPDATE_EMAIL_FAILURE,
			process: '/update-email',
			user_informed: true,
		},
	});
	throw error;
});

export const trackUpdateEmailSuccess = tap(() => {
	track.customLink({
		pageName: UPDATE_EMAIL_PAGE,
		pageSection: LOGIN_SECTION,
		linkName: UPDATE_EMAIL_SUCCESS,
		loginSource: ACCOUNT_MANAGEMENT,
	});
	snowplowExceptionClear();
	trackSnowplowEvent({
		event: 'exception',
		exception: {
			type: 'success',
			message: autoFormatMessage('form.updateEmail.successLinkList'),
			code: UPDATE_EMAIL_SUCCESS,
			process: '/update-email',
			user_informed: true,
		},
	});
});

export const trackResetPasswordSuccess = tap(
	(result, { length, policyScore }) => {
		const relyingParty = getRelyingPartyStorage().get();
		const loginCtx = getLoginCtxStorage().getIn(relyingParty);
		let successMessageId = 'form.resetPassword.success';
		let pathType = 'login source and context exists';
		if (!loginCtx) {
			successMessageId = `form.resetPassword.${
				relyingParty ? 'success' : 'noRelyingParty'
			}`;
			pathType = relyingParty
				? 'login source exists and no context'
				: 'no login source and context';
		}
		track.customLink({
			linkName: RESET_PASSWORD_SUCCESS,
			policyScore,
			length,
			successMessage: autoFormatMessage(successMessageId),
			pathType,
		});
		snowplowExceptionClear();
		trackSnowplowEvent({
			event: 'exception',
			exception: {
				type: 'success',
				message: autoFormatMessage(successMessageId),
				code: RESET_PASSWORD_SUCCESS,
				process: '/reset-password',
				user_informed: true,
			},
		});
	}
);

export const trackResetPasswordFailure = tap((error, { length }) => {
	const { fieldErrors, fieldValues, tags } = error;
	if (tags.errorCode === 'PASSWORD_POLICY_VIOLATION') {
		const {
			passwordStrength: { policyScore },
		} = fieldValues;
		track.customLink({
			linkName: RESET_PASSWORD_FAILURE,
			policyScore,
			length,
			errorMessage: autoFormatMessage(fieldErrors.newPassword),
			errorType: 'application',
			...tags,
		});
		snowplowExceptionClear();
		trackSnowplowEvent({
			event: 'exception',
			exception: {
				type: 'error',
				message: autoFormatMessage(fieldErrors.newPassword),
				code: RESET_PASSWORD_FAILURE,
				process: tags.req.url,
				user_informed: true,
			},
		});
		throw error;
	} else if (tags.errorCode === 'USER_NOT_FOUND') {
		snowplowExceptionClear();
		trackSnowplowEvent({
			event: 'exception',
			exception: {
				type: 'error',
				message: tags.errorMessage,
				code: tags.errorCode,
				process: tags.req.url,
				user_informed: true,
			},
		});
		throw error;
	} else {
		trackGenericException(error);
		trackGenericExceptionSnowplow(error);
	}
});

export const trackCreateAccountSuccess = tap((result, { length, policyScore }) => {
	const relyingParty = getRelyingPartyStorage().get();
	const loginCtx = getLoginCtxStorage().getIn(relyingParty);
	let pathType = 'login source and context exists';

	if (!loginCtx) {
		pathType = relyingParty
			? 'login source exists and no context'
			: 'no login source and context';
	}
	track.customLink({
		linkName: CREATE_ACCOUNT_SUCCESS,
		policyScore,
		length,
		pathType,
	});
});

export const trackCreateAccountSuccessSnowplow = tap(() => {
	trackSnowplowEvent({ event: 'create_account_success' });
});

export const trackLoginSubmit = tap(() => {
	trackSnowplowEvent({ event: 'login_submit' });
});

export const trackSsoLoginSubmit = tap(() => {
	trackSnowplowEvent({ event: 'sso_login_submit' });
});

export const trackCASLEnabled = tap((result, { casl }) => {
	if (casl) {
		track.customLink({
			linkName: CASL_ENABLED,
		});
	}
});

export const trackCreateAccountFailure = tap((error, { length }) => {
	const { fieldErrors, fieldValues, tags } = error;

	if (tags.errorCode === 'PASSWORD_POLICY_VIOLATION') {
		const {
			passwordStrength: { policyScore },
		} = fieldValues;
		track.customLink({
			linkName: CREATE_ACCOUNT_FAILURE,
			policyScore,
			length,
			errorMessage: autoFormatMessage(fieldErrors.newPassword),
			errorType: 'application',
			...tags,
		});
		snowplowExceptionClear();
		trackSnowplowEvent({
			event: 'exception',
			exception: {
				type: 'error',
				message: autoFormatMessage(fieldErrors.newPassword),
				code: CREATE_ACCOUNT_FAILURE,
				process: tags.req.url,
				user_informed: true,
			},
		});
		throw error;
	} else {
		trackGenericException(error);
		trackGenericExceptionSnowplow(error);
	}
});

export const trackSnowplowUser = tap(() => {
	trackSnowplowEvent({
		event: 'user_state',
		user: {
			pcid_id: 'anonymous',
			pco_wallet_id: '',
			login_status: 'not-logged-in',
			registration_status: '',
		},
		sso_session: {
			client: getSnowplowSsoSession().rpName,
			branding: getSnowplowSsoSession().storeName,
		},
	});
});

export const selectVerificationMethodLink = tap(() => {
	track.customLink({
		linkName: MFA_USE_SMS_INSTEAD,
		loginSource: getRelyingPartyStorage().get(),
	});
});

export const selectVerificationMethodBack = tap(() => {
	track.customLink({
		linkName: MFA_SELECT_METHOD_BACK,
		loginSource: getRelyingPartyStorage().get(),
	});
});

export const selectVerificationMethodSubmit = tap(() => {
	track.customLink({
		linkName: MFA_SELECT_METHOD_SUBMIT,
		loginSource: getRelyingPartyStorage().get(),
	});
});

export const selectVerificationMethodSuccess = tap(() => {
	track.customLink({
		linkName: MFA_SELECT_METHOD_SUCCESS,
		loginSource: getRelyingPartyStorage().get(),
	});
});

export const trackSubmitPhoneNumber = tap(() => {
	track.customLink({
		linkName: SUBMIT_PHONE_NUMBER,
		methodSelection: 'sms',
	});
});

export const trackProtectAccountPage = tap(() => {
	track.customLink({
		linkName: SET_UP_2_STEP_VERIFICATION,
	});
});

export const trackCancelMfa = tap(() => {
	track.customLink({
		linkName: CANCEL_MFA,
	});
});

export const trackMFASetupSuccess = tap((_, { authFactor }) => {
	track.customLink({
		linkName: MFA_SETUP_SUCCESS,
		loginStatus: true,
		methodSelection: authFactor,
	});
	snowplowExceptionClear();
	trackSnowplowEvent({
		event: 'exception',
		exception: {
			type: 'success',
			message: MFA_SETUP_SUCCESS,
			process: 'login/verification-required',
			user_informed: false,
		},
	});
});

export const trackUseEmailInstead = tap((path) => {
	track.customLink({
		linkName: MFA_USE_EMAIL_INSTEAD,
	});

	trackSnowplowEvent({
		event: 'link_click',
		data: {
			targetUrl: path,
			elementContent: MFA_USE_EMAIL_INSTEAD,
		},
		sso_session: {
			client: getSnowplowSsoSession().rpName,
			branding: getSnowplowSsoSession().storeName,
		},
	});
});

export const trackBackButton = tap(() => {
	track.customLink({
		linkName: BACK_BUTTON,
	});
});

export const trackContinueAsPageView = tap(() => {
	const { snowplowPayload } = getPageViewPayload('/continue');
	trackSnowplowEvent(snowplowPayload);
});

export const trackLoginPageView = tap(() => {
	const { snowplowPayload } = getPageViewPayload('/login');
	trackSnowplowEvent(snowplowPayload);
});

export const trackCreateAccountPageView = tap(() => {
	const { snowplowPayload } = getPageViewPayload('/create-account');
	trackSnowplowEvent(snowplowPayload);
});

const analytics = {
	getPageViewPayload,
	snowplowExceptionClear,
	trackGenericException,
	trackGenericExceptionSnowplow,
	trackLoginSuccess,
	trackLoginFailure,
	trackMFALoginSuccess,
	trackMFALoginFailure,
	trackMFAResendCode,
	trackEmailAlreadyExists,
	trackForgotPasswordEmail,
	trackUpdateEmailSuccess,
	trackUpdateEmailFailure,
	trackResetPasswordSuccess,
	trackResetPasswordFailure,
	trackCreateAccountSuccess,
	trackCreateAccountFailure,
	trackCASLEnabled,
	selectVerificationMethodLink,
	selectVerificationMethodBack,
	selectVerificationMethodSubmit,
	selectVerificationMethodSuccess,
	trackSubmitPhoneNumber,
	trackProtectAccountPage,
	trackCancelMfa,
	trackMFASetupSuccess,
	trackUseEmailInstead,
	trackContinueAsPageView,
	trackLoginPageView,
};

export default analytics;
