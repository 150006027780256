import Router from 'next/router';
import { parse, stringify } from 'qs';

import handleError from '@pcid/actions/handle-error';
import RequestBuilder from '@pcid/client-request-builder';
import { getKmsiTokenStorage } from '@pcid/storage-utils';

import { getMiddlewareUrl } from '../login';
import { trackGenericException } from '../track-analytics';

export const processLogout = ({ requestBody }) => new RequestBuilder(getMiddlewareUrl())
	.withData(requestBody)
	.post('/logout')
	.then(({ data }) => {
		const { redirectUri } = data;
		getKmsiTokenStorage().remove();
		Router.replace(`/logout/success?${stringify({ redirectUri, data: JSON.stringify(parse(data)) })}`);
	})
	.catch((err) => {
		if (requestBody.redirectUri) {
			const { redirectUri } = requestBody;
			// if an exception is received from Backend, send the error response
			Router.replace(`/logout/success?${stringify({ redirectUri, data: JSON.stringify(parse(err?.response?.data)) })}`);
		} else {
			Router.replace('/');
		}
		handleError(err);
	})
	.catch(() => {}); // ignore error since it's already logged using handleError

const isValidUrl = (urlString) => {
	try {
		const url = new URL(urlString);
		return url.protocol === 'http:' || url.protocol === 'https:';
	} catch (_error) {
		return false;
	}
};

// kmsilogout revokes the KMSI token at IDCS and clear the browser KMSI cookies
export const processKmsiLogout = (redirectUrl) => {
	new RequestBuilder(getMiddlewareUrl())
		.post('/kmsilogout')
		.then(() => {
			getKmsiTokenStorage().remove();
			if (redirectUrl !== undefined && redirectUrl !== 'undefined') {
				if (isValidUrl(redirectUrl)) {
					Router.replace(redirectUrl);
				} else {
					Router.replace('/');
				}
			}
		})
		.catch((err) => {
			if (redirectUrl !== undefined && redirectUrl !== 'undefined') {
				if (isValidUrl(redirectUrl)) {
					Router.replace(redirectUrl);
				} else {
					Router.replace('/');
				}
			}
			handleError(err);
		})
		.catch(trackGenericException)
		.catch(() => { }); // ignore error since it's already logged
};
