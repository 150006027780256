const _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

const noChange = (x) => x;

const isArray = Array.isArray;

const selectProps = function selectProps() {
	for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
		args[_key] = arguments[_key];
	}

	const parsers = {};
	let fromCaseTransform = noChange;
	let toCaseTransform = noChange;

	// If first argument is an array, use that as props and ignore the rest
	var props = isArray(args[0]) ? args[0] : args;

	props.forEach(function (prop) {
		var key = prop;
		var parser = noChange;

		if (prop === Object(prop) && !isArray(prop)) {
			if (prop.key) {
				key = prop.key;
				// if prop has a 'key' property, then look for an explicitly named parser as well

				parser = prop.parser;
			} else {
				var _Object$keys = Object.keys(prop);
				// else, assume the prop object has one key (the prop name) and the value is the parser


				var _Object$keys2 = _slicedToArray(_Object$keys, 1);

				key = _Object$keys2[0];

				parser = prop[key];
			}

			if (typeof parser !== 'function') {
				throw new Error('The parser supplied for key \'' + key + '\' must be a function!');
			}
		}

		parsers[key] = parser;
	});

	var selector = function selector(from) {
		if (!from) {
			return from;
		}

		var to = {};
		Object.keys(parsers).forEach(function (key) {
			var fromKey = fromCaseTransform(key);
			var toKey = toCaseTransform(key);
			to[toKey] = parsers[key](from[fromKey], from);
		});

		return to;
	};

	selector.withCaseTransform = function (_ref) {
		var from = _ref.from,
			to = _ref.to;

		if (from) {
			if (typeof from !== 'function') {
				throw new Error('The "from" transform supplied to withCaseTransform must be a function!');
			}
			fromCaseTransform = from;
		}

		if (to) {
			if (typeof to !== 'function') {
				throw new Error('The "to" transform supplied to withCaseTransform must be a function!');
			}
			toCaseTransform = to;
		}

		return selector;
	};

	// Sugary alias
	selector.from = selector;

	return selector;
};

export default selectProps;
