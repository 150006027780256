import Router from 'next/router';
import { parse, stringify } from 'qs';
import selectProps from '../select-props';

const hideQueryParams = (...keys) => {
	const { asPath = '/', query = {}, pathname = '/' } = Router;
	const asQuery = parse(asPath.split('?')[1]);

	const href = `${pathname}${stringify(
		{ ...query, ...selectProps(...keys).from(asQuery) },
		{ addQueryPrefix: true }
	)}`;
	const as = `${pathname}${stringify(
		Object.keys(asQuery).reduce((sanitized, key) => (
			keys.includes(key)
				? sanitized
				: { ...sanitized, [key]: asQuery[key] }
		), {}),
		{ addQueryPrefix: true }
	)}`;
	return Router.replace(href, as, { shallow: true });
};

export default hideQueryParams;
